@font-face {
font-family: 'recklessNeue';
src: url(/_next/static/media/4199399bb8246861-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'recklessNeue Fallback';src: local("Arial");ascent-override: 88.98%;descent-override: 20.75%;line-gap-override: 0.00%;size-adjust: 102.16%
}.__className_05c170 {font-family: 'recklessNeue', 'recklessNeue Fallback', sans-serif
}.__variable_05c170 {--f-serif: 'recklessNeue', 'recklessNeue Fallback', sans-serif
}

@font-face {
font-family: 'activeGrotesk';
src: url(/_next/static/media/0df46036a6d08592-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'activeGrotesk';
src: url(/_next/static/media/c0847dfc75394886-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'activeGrotesk';
src: url(/_next/static/media/fad79c547a2a4c74-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'activeGrotesk';
src: url(/_next/static/media/ea36785919cba894-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'activeGrotesk Fallback';src: local("Arial");ascent-override: 98.59%;descent-override: 23.74%;line-gap-override: 0.00%;size-adjust: 102.34%
}.__className_4a9d2f {font-family: 'activeGrotesk', 'activeGrotesk Fallback', sans-serif
}.__variable_4a9d2f {--f-sans: 'activeGrotesk', 'activeGrotesk Fallback', sans-serif
}

@font-face {
font-family: 'activeGroteskHeadings';
src: url(/_next/static/media/0df46036a6d08592-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'activeGroteskHeadings';
src: url(/_next/static/media/c0847dfc75394886-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'activeGroteskHeadings';
src: url(/_next/static/media/fad79c547a2a4c74-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'activeGroteskHeadings';
src: url(/_next/static/media/ea36785919cba894-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'activeGroteskHeadings Fallback';src: local("Arial");ascent-override: 98.59%;descent-override: 23.74%;line-gap-override: 0.00%;size-adjust: 102.34%
}.__className_5d31f3 {font-family: 'activeGroteskHeadings', 'activeGroteskHeadings Fallback', sans-serif
}.__variable_5d31f3 {--f-serif: 'activeGroteskHeadings', 'activeGroteskHeadings Fallback', sans-serif
}

